<template>
  <div>
    <setting-quota
      @refresh-data="refreshCustomerInfoData"
    />
  </div>
</template>

<script>
import SettingQuota from './CustomerViewSettingQuota.vue'

export default {
  components: {
    SettingQuota,
  },
  data() {
    return {
    }
  },
  methods: {
    refreshCustomerInfoData() {
      this.$emit('refresh-data')
    },
  },
  setup() {
    return {
    }
  },
}
</script>
