<template>
  <div>
    <b-skeleton-wrapper :loading="adminCustomerState.isBusyLoading">
      <template #loading>
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              基本資料
            </h4>
          </b-card-header>

          <b-card-body class="ml-50">
            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <b-skeleton
                  animation="wave"
                  width="90%"
                />

                <b-skeleton
                  animation="wave"
                  width="90%"
                />
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-skeleton
                  animation="wave"
                  width="90%"
                />

                <b-skeleton
                  animation="wave"
                  width="90%"
                />
              </b-col>
            </b-row>
          </b-card-body>

          <hr class="m-0">

          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <div class="mb-2">
                  <div class="d-flex mb-50 text-primary">
                    <feather-icon
                      icon="UserIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50 text-primary">
                      個人資料
                    </h4>
                  </div>

                  <div class="ml-50">
                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />

                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />

                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />

                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />
                  </div>
                </div>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <div class="mb-2">
                  <div class="d-flex mb-50 text-primary">
                    <feather-icon
                      icon="ServerIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50 text-primary">
                      系統設定
                    </h4>
                  </div>

                  <div class="ml-50">
                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />

                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />
                  </div>
                </div>

                <div>
                  <div class="d-flex mb-50 text-primary">
                    <feather-icon
                      icon="MapPinIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50 text-primary">
                      聯繫方式
                    </h4>
                  </div>

                  <div class="ml-50">
                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />

                    <b-skeleton
                      animation="wave"
                      width="90%"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <div v-if="!adminCustomerState.isBusyLoading">
      <b-overlay
        :show="isBusy"
        variant="transparent"
        opacity="0.85"
        rounded="sm"
        blur="5px"
      >
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              基本資料
            </h4>

            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="$emit('refresh-data')"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="儲存"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="submitUpdateCustomerInfo"
              >
                <b-img
                  src="/dashboard/admin/images/table/save.svg"
                  class="actions-link-btn-image"
                />
              </div>
            </div>
          </b-card-header>

          <b-card-body class="ml-50">
            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="SSID"
                  class="m-0 preview-col-from-group"
                >
                  <div class="mt-50 preview-col-label">
                    {{ adminCustomerState.customerInfo.ssid }}
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="登入IP"
                  class="m-0 preview-col-from-group"
                >
                  <div class="mt-50 preview-col-label">
                    {{ adminCustomerState.customerInfo.last_login_ip ? adminCustomerState.customerInfo.last_login_ip : '---' }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="創建位置"
                  class="m-0 preview-col-from-group"
                >
                  <div class="mt-50 preview-col-label">
                    {{ adminCustomerState.customerInfo.register_ip ? adminCustomerState.customerInfo.register_ip : '---' }}
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="創建時間"
                  class="m-0 preview-col-from-group"
                >
                  <div class="mt-50 preview-col-label">
                    {{ adminCustomerState.customerInfo.created_at ? moment(adminCustomerState.customerInfo.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>

          <hr class="m-0">

          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <div class="mb-2">
                  <div class="d-flex mb-50 text-primary">
                    <feather-icon
                      icon="UserIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50 text-primary">
                      個人資料
                    </h4>
                  </div>

                  <div class="ml-50">
                    <b-form-group
                      label="會員名稱"
                      label-for="name"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'name'"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="name"
                          v-model="generalData.name"
                          :placeholder="editColData ? editColData : '請輸入會員姓名'"
                        />

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('name')"
                      >
                        <span>
                          {{ generalData.name ? generalData.name : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label="姓氏"
                      label-for="family_name"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'family_name'"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="family_name"
                          v-model="generalData.family_name"
                          :placeholder="editColData ? editColData : '請輸入姓氏'"
                        />

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('family_name')"
                      >
                        <span>
                          {{ generalData.family_name ? generalData.family_name : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label="性別"
                      label-for="sex"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'sex'"
                        class="input-group-merge"
                      >
                        <v-select
                          v-model="generalData.sex"
                          :options="genderOptions"
                          :reduce="option => option.value"
                          class="edit-col-select"
                        >
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無資料</small>
                            </div>
                          </template>
                        </v-select>

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('sex')"
                      >
                        <span v-if="generalData.sex !== null">
                          {{ ui.genderList[generalData.sex] ? ui.genderList[generalData.sex] : '尚未設定' }}
                        </span>

                        <span
                          v-else
                          class="text-muted"
                        >
                          尚未設定
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label="生日"
                      label-for="birthday"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'birthday'"
                        class="input-group-merge"
                      >
                        <cleave
                          id="birthday"
                          v-model="generalData.birthday"
                          class="form-control"
                          :raw="false"
                          :options="options.date"
                          placeholder="YYYY-MM-DD"
                        />

                        <!-- <b-input-group-append>
                          <b-form-datepicker
                            v-model="generalData.birthday"
                            show-decade-nav
                            button-only
                            button-variant="outline-primary"
                            right
                            size="sm"
                            locale="zh-TW"
                            aria-controls="example-input"
                            :date-disabled-fn="dateDisabled"
                          />
                        </b-input-group-append> -->

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('birthday')"
                      >
                        <span>
                          {{ generalData.birthday ? generalData.birthday : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <div class="mb-2">
                  <div class="d-flex mb-50 text-primary">
                    <feather-icon
                      icon="ServerIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50 text-primary">
                      系統設定
                    </h4>
                  </div>

                  <div class="ml-50">
                    <b-form-group
                      label="分站"
                      label-for="branch_id"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'branch_id'"
                        class="input-group-merge"
                      >
                        <v-select
                          v-model="generalData.branch_id"
                          :options="adminCustomerState.branchOptions"
                          :reduce="option => option.value"
                          class="edit-col-select"
                        >
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無資料</small>
                            </div>
                          </template>
                        </v-select>

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('branch_id')"
                      >
                        <span>
                          {{ generalData.branch_id ? resolveBranchOptions(generalData.branch_id).label : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label="狀態"
                      label-for="state"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'state'"
                        class="input-group-merge"
                      >
                        <v-select
                          v-model="generalData.state"
                          :options="stateOptions"
                          :reduce="option => option.value"
                          :clearable="false"
                          class="edit-col-select"
                        >
                          <!-- :placeholder="editColData ? editColData : '請選擇狀態'" -->
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無資料</small>
                            </div>
                          </template>
                        </v-select>

                        <!-- {{ stateOptions }} -->

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('state')"
                      >
                        <span>
                          {{ ui.stateList[0][generalData.state] ? ui.stateList[0][generalData.state] : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div>
                  <div class="d-flex mb-50 text-primary">
                    <feather-icon
                      icon="MapPinIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50 text-primary">
                      聯繫方式
                    </h4>
                  </div>

                  <div class="ml-50">
                    <b-form-group
                      label="電子信箱"
                      label-for="account"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'account'"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="account"
                          v-model="generalData.account"
                          :placeholder="editColData ? editColData : '請輸入電子信箱'"
                        />

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('account')"
                      >
                        <span>
                          {{ generalData.account ? generalData.account : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label="聯絡電話"
                      label-for="phone"
                      label-cols="4"
                      label-cols-lg="5"
                      label-cols-xl="4"
                      class="edit-col-from-group"
                    >
                      <b-input-group
                        v-if="editCol === 'phone'"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="phone"
                          v-model="generalData.phone"
                          :placeholder="editColData ? editColData : '請輸入聯絡電話'"
                        />

                        <b-input-group-append is-text>
                          <div>
                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                              width="18"
                              class="mr-75 cursor-pointer"
                              @click="confirmEditCol"
                            />

                            <b-img
                              :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                              width="18"
                              class="cursor-pointer"
                              @click="cancelEditCol"
                            />
                          </div>
                        </b-input-group-append>
                      </b-input-group>

                      <div
                        v-else
                        class="edit-col-label"
                        @click="selectEditCol('phone')"
                      >
                        <span>
                          {{ generalData.phone ? generalData.phone : '---' }}
                        </span>

                        <span class="selection-btn-icon">
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            height="15"
                            width="15"
                            class="cursor-pointer"
                          />
                        </span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-overlay>

      <!-- {{ generalData }} -->
    </div>
  </div>
</template>

<script>
// API
import moment from 'moment'
// UI
import {
  BSkeletonWrapper, BSkeleton, BCard, BCardBody, BRow, BCol, BFormGroup, BImg, BCardHeader,
  BFormInput, BInputGroup, BInputGroupAppend, VBTooltip, BOverlay, // BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { useCustomerViewGeneral, useCustomerSetting, useCustomerList } from '../../useCustomer'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
// import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormGroup,
    BCardBody,
    BCardHeader,
    BOverlay,
    // BCardText,
    BSkeleton,
    BSkeletonWrapper,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // BFormDatepicker,

    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
      },
      editCol: null,
      editColData: null,
    }
  },
  computed: {
    adminCustomerState() {
      return this.$store.state['admin-customer']
    },
  },
  watch: {
    'adminCustomerState.customerInfo': {
      handler() {
        this.initCustomerInfo()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminCustomerState.customerInfo) {
      this.initCustomerInfo()
    }
  },
  methods: {
    moment,
    // (初始化)會員資料
    initCustomerInfo() {
      const resolveData = {
        ...this.syncObject(this.blankGeneralData, this.adminCustomerState.customerInfo),
      }
      this.generalData = resolveData
    },

    // (選取)編輯欄位
    selectEditCol(type) {
      this.editCol = type
      this.editColData = this.generalData[type]
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.generalData[this.editCol] = this.editColData
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      // this.onSubmitUpdate()
      this.editCol = null
    },

    // 鎖定日期
    dateDisabled(ymd, date) {
      return date > moment().endOf('day')
    },

    // (查詢)分站資訊
    resolveBranchOptions(id) {
      return this.adminCustomerState.branchOptions.find(item => item.value === id)
    },

    // (觸發)儲存
    submitUpdateCustomerInfo() {
      if (this.editCol) {
        this.useAlertToast(false, '尚有編輯中的欄位未確認')
        return
      }
      if (this.isBusy) return
      this.isBusy = true
      this.setCustomerUpdate({
        customer_id: this.adminCustomerState.customerInfo.id,
        data: {
          ...this.generalData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          const { data } = response.data
          const resolveData = {
            ...this.syncObject(this.blankCustomerData, data),
            branch_info: data.branch_id && data.branch_info ? this.syncObject(this.blankbranchInfo, data.branch_info) : this.syncObject(this.blankbranchInfo, {}),
          }

          this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveData)
          // this.$emit('refresh-data')
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
  },
  setup() {
    const {
      ui,
      genderOptions,
      stateOptions,
    } = useCustomerSetting()

    const {
      syncObject,
      generalData,
      setCustomerUpdate,
    } = useCustomerViewGeneral()

    const {
      isBusy,
      blankCustomerData,
      blankbranchInfo,
    } = useCustomerList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankGeneralData = {
      name: null,
      family_name: null,
      account: null,
      phone: null,
      sex: null,
      birthday: null,
      branch_id: null,
      state: false,
      // email_state: 0,
      // kyc_state: 0,
      // phone_state: 0,
    }

    const resetGeneralData = () => {
      generalData.value = JSON.parse(JSON.stringify(blankGeneralData))
      isBusy.value = false
    }

    resetGeneralData()

    return {
      isBusy,
      generalData,
      resetGeneralData,
      syncObject,
      blankGeneralData,
      blankCustomerData,
      blankbranchInfo,
      useAlertToast,
      useHttpCodeAlert,

      setCustomerUpdate,

      ui,
      genderOptions,
      stateOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.edit-col-from-group {
  .vs__dropdown-toggle {
    border-radius: 5px 0 0 5px;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout {
  .edit-col-from-group {
    .edit-col-label {
      &:hover {
        background: #aaaaaa20;
      }
    }
  }
}

.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      // box-shadow: 0 4px 4px 0 rgba(#6f6f6f, 0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    // background-color: red;
    width: calc(100% - 80px);
  }
}

.preview-col-from-group {
  .preview-col-label {
    padding: 0 10px;
  }
}
</style>
