<template>
  <div>
    <b-tabs
      pills
      lazy
    >
      <!-- tab: 訂單列表 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ListIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">訂單列表</span>
        </template>

        <!-- <general-base
          @refresh-data="refreshCustomerInfoData"
        /> -->
      </b-tab>

      <!-- tab: 購物車列表 -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ShoppingCartIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">購物車列表</span>
        </template>

        <order-cart-list />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import OrderCartList from './CustomerViewOrderCartList.vue'

// import router from '@/router'
// import store from '@/store'
// import { useCustomerViewGeneral } from '../../useCustomer'

export default {
  components: {
    BTabs,
    BTab,
    OrderCartList,
  },
  data() {
    return {
      // tabIndex: 0,
    }
  },
  methods: {
  },
  setup() {
    // const customerID = router.currentRoute.params.id

    return {
    }
  },
}
</script>
