<template>
  <div>
    <div>
      <b-overlay
        :show="isBusy"
        variant="transparent"
        opacity="0.85"
        rounded="sm"
        blur="5px"
      >
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              權限驗證
            </h4>
          </b-card-header>

          <!-- 信箱驗證 -->
          <b-card-body class="pb-0">
            <div class="kyc-card">
              <div class="d-flex justify-content-between flex-row w-100 mb-1">
                <div class="d-flex justify-content-start">
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/customer/email.svg')"
                      height="45"
                      rounded
                    />
                  </div>

                  <div class="ml-1">
                    <h4 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                      信箱驗證
                    </h4>

                    <small>
                      檢查信箱是否可正常接收郵件
                      <!-- {{ adminCustomerState.customerInfo.account }} -->
                    </small>
                  </div>
                </div>

                <div
                  v-if="!adminCustomerState.isBusyLoading"
                  class="cursor-pointer"
                  @click="submitKycModeSetting('account')"
                >
                  <b-alert
                    :variant="ui.kycState[1][adminCustomerState.customerInfo.email_state]"
                    show
                  >
                    <div class="alert-body text-nowrap">
                      <span>{{ ui.kycState[0][adminCustomerState.customerInfo.email_state] }}</span>
                    </div>
                  </b-alert>
                </div>
              </div>

              <div
                v-if="!adminCustomerState.isBusyLoading"
                class="mt-50 text-right kyc-card-btns"
              >
                <b-link
                  class="text-body-heading"
                >
                  <button class="ml-1 kyc-card-btn">
                    重發認證信
                  </button>
                </b-link>

                <b-link
                  class="text-body-heading"
                  @click="submitKycModeSetting('account')"
                >
                  <button class="ml-1 kyc-card-btn">
                    設定
                  </button>
                </b-link>
              </div>
            </div>
          </b-card-body>

          <!-- 手機驗證 -->
          <b-card-body class="pb-0">
            <div class="kyc-card">
              <div class="d-flex justify-content-between flex-row w-100">
                <div class="d-flex justify-content-start">
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/customer/security.svg')"
                      height="45"
                      rounded
                    />
                  </div>

                  <div class="ml-1">
                    <h4 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                      手機驗證
                    </h4>

                    <small>
                      確認手機號碼是否有效
                      <!-- {{ adminCustomerState.customerInfo.phone }} -->
                    </small>
                  </div>
                </div>

                <div
                  v-if="!adminCustomerState.isBusyLoading"
                  class="cursor-pointer"
                  @click="submitKycModeSetting('phone')"
                >
                  <b-alert
                    :variant="ui.kycState[1][adminCustomerState.customerInfo.phone_state]"
                    show
                  >
                    <div class="alert-body text-nowrap">
                      <span>{{ ui.kycState[0][adminCustomerState.customerInfo.phone_state] }}</span>
                    </div>
                  </b-alert>
                </div>
              </div>

              <div
                v-if="!adminCustomerState.isBusyLoading"
                class="mt-50 text-right kyc-card-btns"
              >
                <b-link
                  class="text-body-heading"
                >
                  <button
                    class="ml-1 kyc-card-btn"
                    disabled
                  >
                    重發簡訊
                  </button>
                </b-link>

                <b-link
                  class="text-body-heading"
                  @click="submitKycModeSetting('phone')"
                >
                  <button class="ml-1 kyc-card-btn">
                    設定
                  </button>
                </b-link>
              </div>
            </div>
          </b-card-body>

          <!-- 證件驗證 -->
          <b-card-body>
            <div class="kyc-card">
              <div class="d-flex justify-content-between flex-row w-100">
                <div class="d-flex justify-content-start">
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/customer/id-card.svg')"
                      height="45"
                      rounded
                    />
                  </div>

                  <div class="ml-1">
                    <h4 class="d-flex align-items-center font-weight-bolder mr-25 mb-25">
                      身分驗證
                    </h4>

                    <small>
                      驗證用戶身份是否真實
                    </small>
                  </div>
                </div>

                <div
                  v-if="!adminCustomerState.isBusyLoading"
                  class="cursor-pointer"
                  @click="submitKycModeSetting('identity')"
                >
                  <b-alert
                    :variant="ui.kycState[1][adminCustomerState.customerInfo.kyc_state]"
                    show
                  >
                    <div class="alert-body text-nowrap">
                      <span>{{ ui.kycState[0][adminCustomerState.customerInfo.kyc_state] }}</span>
                    </div>
                  </b-alert>
                </div>
              </div>

              <div
                v-if="!adminCustomerState.isBusyLoading"
                class="mt-50 text-right kyc-card-btns"
              >
                <b-link
                  class="text-body-heading"
                  @click="submitAddIdentityCard"
                >
                  <button
                    class="ml-1 kyc-card-btn"
                    :class="identityCardList.length >= 3 ? 'kyc-card-btn-disabled' : null"
                  >
                    新增證件
                  </button>
                </b-link>

                <b-link
                  class="text-body-heading"
                  @click="submitKycModeSetting('identity')"
                >
                  <button class="ml-1 kyc-card-btn">
                    設定
                  </button>
                </b-link>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>

      <!-- 證件驗證 -->
      <b-card
        no-body
        class="animate__animated animate__fadeIn"
      >
        <b-card-header>
          <h4 class="mb-0">
            證件驗證
          </h4>

          <div
            v-if="identityCardList.length < 3"
            class="text-nowrap d-flex justify-content-end"
          >
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="新增證件"
              class="d-flex align-items-center actions-link-btn"
              @click="submitAddIdentityCard"
            >
              <b-img
                src="/dashboard/admin/images/table/plus.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </b-card-header>

        <b-card-body v-if="adminCustomerState.customerInfo">
          <div
            v-if="identityCardList.length > 0"
            class="identity-card-container"
          >
            <div
              v-for="(card, index) in identityCardList"
              :key="card.key"
              class="identity-card border mb-1"
            >
              <div class="identity-card-image">
                <div class="content-image">
                  <VueLoadImage
                    v-if="card.image"
                    :use-loading="true"
                    :use-failed="true"
                    :blank-height="'100px'"
                    :loading-width="60"
                    :loading-height="60"
                    :failed-width="100"
                  >
                    <b-img
                      slot="image"
                      :src="card.image"
                      fluid
                      width="250"
                      class="identity-image"
                      alt="會員證件"
                      @click="showImgs(card)"
                    />
                  </VueLoadImage>

                  <div
                    v-else
                    class="identity-image-empty"
                    @click="submitUploadImage(card, index)"
                  >
                    <b-img
                      :src="require('@/assets/images/pages/customer/identity-card.svg')"
                      rounded
                      height="60"
                      width="60"
                      class="mb-25"
                    />
                    <span class="empty-text">請上傳圖片</span>
                  </div>
                </div>

                <!-- {{ card.image }} -->
              </div>

              <div class="identity-card-action">
                <button
                  class="mr-1 identity-card-action-btn action-btn-upload"
                  @click="submitUploadImage(card, index)"
                >
                  {{ !card.image ? '上傳' : '編輯' }}
                </button>

                <button
                  class="identity-card-action-btn action-btn-delete"
                  @click="submitRemoveIdentityCard(index)"
                >
                  移除
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="identityCardList.length === 0"
            class="text-center animate__animated animate__fadeIn border identity-card p-3"
          >
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="300"
              alt="查無資料"
            />
          </div>
        </b-card-body>

        <b-card-body v-else>
          <div class="loading-area">
            <b-img
              :src="$store.state.app.themeImages.loadingImg"
              rounded
              height="60"
              width="60"
            />
          </div>
        </b-card-body>
      </b-card>

      <!-- {{ adminCustomerState.customerInfo }} -->
    </div>

    <!-- 上傳圖片 -->
    <b-form-file
      ref="refInputEl"
      accept=".jpg, .png"
      :hidden="true"
      plain
      @change="loadImage($event)"
    />

    <!-- 圖片裁剪 -->
    <b-modal
      id="kyc-image-modal"
      centered
      :no-close-on-backdrop="close"
      :no-close-on-esc="close"
    >

      <template #modal-header>
        <h4>圖片裁剪</h4>
      </template>

      <div class="upload-example">
        <!-- {{ image }} -->
        <cropper
          ref="cropper"
          :src="image"
          :canvas="{
            minHeight: 0,
            minWidth: 0,
            maxHeight: 300,
            maxWidth: 300,
          }"
          :auto-zoom="false"
          :debounce="false"
          @change="onChange"
        />

        <!-- <preview
          :width="200"
          :height="200"
          :image="result.image"
          :coordinates="result.coordinates"
        /> -->
      </div>

      <template #modal-footer>
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>

        <b-button
          size="sm"
          variant="primary"
          @click="uploadImage"
        >
          上傳
        </b-button>
      </template>
    </b-modal>

    <!-- 驗證模組 -->
    <b-modal
      v-if="adminCustomerState.customerInfo"
      id="kyc-modal"
      centered
      no-close-on-backdrop
    >
      <template #modal-header>
        <h4 class="mb-0">
          {{ ui.kycMode[kycMode] }}
        </h4>
      </template>

      <div>
        <label>狀態</label>

        <v-select
          v-if="kycMode === 'account'"
          v-model="generalData.email_state"
          :options="stateOptions('account')"
          :reduce="option => option.value"
          :clearable="false"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              沒有<em>{{ search }}</em> 的相關結果
            </template>

            <div v-else>
              <small>暫無資料</small>
            </div>
          </template>
        </v-select>

        <v-select
          v-if="kycMode === 'phone'"
          v-model="generalData.phone_state"
          :options="stateOptions('phone')"
          :reduce="option => option.value"
          :clearable="false"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              沒有<em>{{ search }}</em> 的相關結果
            </template>

            <div v-else>
              <small>暫無資料</small>
            </div>
          </template>
        </v-select>

        <v-select
          v-if="kycMode === 'identity'"
          v-model="generalData.kyc_state"
          :options="stateOptions('identity')"
          :reduce="option => option.value"
          :clearable="false"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              沒有<em>{{ search }}</em> 的相關結果
            </template>

            <div v-else>
              <small>暫無資料</small>
            </div>
          </template>
        </v-select>
      </div>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="handleCancel"
        >
          取消
        </b-button>

        <b-button
          variant="primary"
          @click="submitUpdateCustomerInfo"
        >
          確認
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BImg, VBTooltip, BAlert, BLink, BFormFile, BModal, BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useCustomerViewGeneral, useCustomerSetting, useCustomerList } from '../../useCustomer'
// import LockImage from '@/layouts/components/ImageLoader/LockImage.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BImg,
    BLink,
    BAlert,
    BFormFile,
    BModal,
    BButton,
    BOverlay,

    VueLoadImage,
    Cropper,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      identityKey: ['identity', '2nd_identity', '3rd_identity'],
      identityCardList: [],
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
      kycMode: null,
      uploadKey: null,
      uploadIndex: null,
    }
  },
  computed: {
    adminCustomerState() {
      return this.$store.state['admin-customer']
    },
  },
  watch: {
    'adminCustomerState.customerInfo': {
      handler() {
        this.initIdentityCardList()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminCustomerState.customerInfo) {
      this.initIdentityCardList()
    }
  },
  methods: {
    // (初始化)證件
    initIdentityCardList() {
      this.identityCardList = []

      this.identityKey.forEach(key => {
        if (this.adminCustomerState.customerInfo[key]) {
          this.identityCardList.push({
            key,
            image: this.adminCustomerState.customerInfo[key],
          })
        }
      })

      const resolveData = {
        ...this.syncObject(this.blankGeneralData, this.adminCustomerState.customerInfo),
      }
      this.generalData = resolveData
    },

    stateOptions(type) {
      const branchInfo = this.adminCustomerState.customerInfo.branch_info
      if (type === 'account') {
        if (branchInfo.mail_valid_switch) {
          const options = [
          // { label: '不需驗證', value: 0 },
            { label: '尚未驗證', value: 1 },
            { label: '已驗證', value: 2 },
          ]
          return options
        }

        if (!branchInfo.mail_valid_switch) {
          return [
            { label: '不需驗證', value: 0 },
            { label: '已驗證', value: 2 },
          ]
        }
      }
      if (type === 'phone') {
        if (branchInfo.phone_valid_switch) {
          const options = [
            { label: '尚未驗證', value: 1 },
            { label: '已驗證', value: 2 },
          ]
          return options
        }

        if (!branchInfo.phone_valid_switch) {
          return [
            { label: '不需驗證', value: 0 },
            { label: '已驗證', value: 2 },
          ]
        }
      }
      if (type === 'identity') {
        return [
          { label: '尚未驗證', value: 1 },
          { label: '已驗證', value: 2 },
          { label: '待驗證', value: 3 },
        ]
      }
      return []
    },

    // (觸發)驗證模式設定
    submitKycModeSetting(mode) {
      this.kycMode = mode
      this.$bvModal.show('kyc-modal')
    },

    // (觸發)儲存
    submitUpdateCustomerInfo() {
      if (this.isBusy) return
      this.isBusy = true
      this.setCustomerUpdate({
        customer_id: this.adminCustomerState.customerInfo.id,
        data: {
          ...this.generalData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          const { data } = response.data
          const resolveData = {
            ...this.syncObject(this.blankCustomerData, data),
            branch_info: data.branch_id && data.branch_info ? this.syncObject(this.blankbranchInfo, data.branch_info) : this.syncObject(this.blankbranchInfo, {}),
          }

          this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveData)
          this.isBusy = false
          this.$bvModal.hide('kyc-modal')
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
          this.$bvModal.hide('kyc-modal')
        })
    },

    // 取消上傳
    handleCancel() {
      this.$bvModal.hide('kyc-modal')
    },
    // -----------------------------------圖片上傳------------------------------------
    // (觸發)新增證件
    submitAddIdentityCard() {
      for (let i = 0; i < this.identityKey.length; i += 1) {
        const key = this.identityKey[i]
        let exists = false
        for (let j = 0; j < this.identityCardList.length; j += 1) {
          if (this.identityCardList[j].key === key) {
            exists = true
            break
          }
        }
        if (!exists) {
          this.identityCardList.push({
            key,
            image: null,
          })
          break
        }
      }
    },

    // (觸發)移除證件
    submitRemoveIdentityCard(index) {
      this.useSwalAlertWarning('刪除證件', '你確定要永久刪除該證件圖片嗎?')
        .then(result => {
          if (result.value) {
            if (!this.identityCardList[index].image) {
              this.identityCardList.splice(index, 1)
              return
            }
            this.setCustomerImageKycDelete({
              customer_id: this.adminCustomerState.customerInfo.id,
              data: {
                key: this.identityCardList[index].key,
              },
            }).then(response => {
              const resolveCustomerInfo = {
                ...this.adminCustomerState.customerInfo,
              }
              resolveCustomerInfo[this.identityCardList[index].key] = null
              this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveCustomerInfo)
              this.identityCardList.splice(index, 1)
              this.useAlertToast(response.data.success, response.data.message)
            })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
              })
          }
        })
    },

    // (觸發)上傳證件
    submitUploadImage(card, index) {
      this.uploadKey = card.key
      this.uploadIndex = index
      this.$refs.refInputEl.$el.click()
    },

    // (觸發)圖片顯示
    showImgs(card) {
      this.$viewerApi({
        images: [card.image],
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (!['JPG', 'PNG'].includes(type.toUpperCase())) return
      this.$bvModal.show('kyc-image-modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('key', this.uploadKey)
          formData.append('data', blob)
          this.setCustomerImageKycUpload({
            customer_id: this.adminCustomerState.customerInfo.id,
            data: formData,
          })
            .then(response => {
              const image = response.data.data
              const resolveCustomerInfo = {
                ...this.adminCustomerState.customerInfo,
              }
              resolveCustomerInfo[this.uploadKey] = image
              this.identityCardList[this.uploadIndex].image = image
              this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveCustomerInfo)
              this.useAlertToast(response.data.success, response.data.message)
            })
            .catch(error => {
              this.useHttpCodeAlert(error.response)
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('kyc-image-modal')
      this.result.coordinates = null
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('kyc-image-modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },
  },
  setup() {
    const {
      ui,
      // genderOptions,
      // stateOptions,
    } = useCustomerSetting()

    const {
      syncObject,
      generalData,
      setCustomerUpdate,
      setCustomerImageKycUpload,
      setCustomerImageKycDelete,
    } = useCustomerViewGeneral()

    const {
      isBusy,
      blankCustomerData,
      blankbranchInfo,
    } = useCustomerList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankGeneralData = {
      email_state: 0,
      kyc_state: 0,
      phone_state: 0,
    }

    const resetGeneralData = () => {
      generalData.value = JSON.parse(JSON.stringify(blankGeneralData))
      isBusy.value = false
    }

    resetGeneralData()

    return {
      ui,
      isBusy,
      blankCustomerData,
      blankbranchInfo,
      syncObject,
      generalData,
      blankGeneralData,
      setCustomerUpdate,
      setCustomerImageKycUpload,
      setCustomerImageKycDelete,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.dark-layout {
  .identity-card {
    .identity-card-image {
      border-color: $theme-dark-border-color;
      .content-image {
        border-color: $theme-dark-border-color;
      }
    }
  }
}

.kyc-card {
  padding: 20px;
  border: 1px solid #b6b4bf47;
  border-radius: 10px;
  .kyc-card-btns {
    .kyc-card-btn {
      background-color: #ffffff00;
      color: #419fd9;
      border: #419fd9 1px solid;
      border-radius: 5px;
      text-align: right;
      padding: 5px 10px;
      transition: all 0.5s ease;
      &:hover {
        background-color: #419fd9;
        color: #fff;
        cursor: pointer;
        // border-radius: 5px;
      }
    }

    .kyc-card-btn-disabled {
      border: 1px solid #b6b4bf47;
      background-color: transparent;
      color: #b6b4bf47;
      cursor: none;
      &:hover {
        background-color: transparent;
        color: #b6b4bf47;
        cursor: auto;
        // border-radius: 5px;
      }
    }
  }
}

.identity-card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 5px;

  .identity-card-image {
    width: 70%;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
    border-right: 1px solid $border-color;
    .content-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border: 2px dashed $border-color;
      border-radius: 10px;
      height: 180px;
      width: 280px;
      .identity-image {
        max-width: 100%;
        max-height: 150px;
        border-radius: 10px;
        cursor: pointer;
      }

      .identity-image-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // background-color: red;
        height: 180px;
        width: 280px;
        cursor: pointer;
        .empty-text {
          font-size: 16px;
          letter-spacing: 1.5px;
          font-weight: bold;
        }
      }
    }
  }
  .identity-card-action {
    width: 30%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .identity-card-action-btn {
      border: none;
      outline: none;
      padding: 8px 25px;
      font-size: 14px;
      font-weight: 700;
      border-radius: 5px;
      white-space: nowrap;
      transition: all ease 0.1s;
      &:active {
        transform: translateY(5px);
      }
    }
    .action-btn-upload {
      background-color: #60b7ff;
      color: #fff;
      box-shadow: 0px 5px 0px 0px #b1ddff;
      &:active {
        box-shadow: 0px 0px 0px 0px #b1ddff;
      }
    }

    .action-btn-delete {
      background-color: #fe9661;
      color: #fff;
      box-shadow: 0px 5px 0px 0px #ffc1a3;
      &:active {
        box-shadow: 0px 0px 0px 0px #ffc1a3;
      }
    }
  }
}

@media (max-width: 766px) {
  .identity-card {
    .identity-card-image {
      width: 100%;
      // border-bottom: 1px solid $border-color;
      border-right: none;
      padding-bottom: 0;
      .content-image {
        // background-color: red;
        width: 100%;
      }
    }

    .identity-card-action {
      width: 100%;
      padding: 10px;
      justify-content: end;
      .identity-card-action-btn {
        padding: 5px 15px;
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 5px;
      }
    }
  }
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
</style>
