<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      vertical
      content-class="col-12 col-lg-10 mt-1 mt-md-0"
      pills
      lazy
      nav-wrapper-class="col-lg-2 col-12"
      nav-class="nav-left"
    >
      <template slot="default">
        <customer-view-info
          @refresh-data="refreshCustomerInfoData"
        />
      </template>

      <!-- tab: 會員詳情 -->
      <b-tab>
        <template #title>
          <b-img
            rounded
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-user.svg')"
          />
          <span class="font-weight-bold">會員詳情</span>
        </template>

        <customer-view-general
          @refresh-data="refreshCustomerInfoData"
        />
      </b-tab>

      <!-- tab: 訂單列表 -->
      <b-tab>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-order.svg')"
          />
          <span class="font-weight-bold">訂單列表</span>
        </template>

        <customer-view-order />
      </b-tab>

      <!-- tab: 工作階段 -->
      <b-tab>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-login.svg')"
          />
          <span class="font-weight-bold">工作階段</span>
        </template>

        <customer-view-workphase />
      </b-tab>

      <!-- tab: 操作紀錄 -->
      <b-tab>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-history.svg')"
          />
          <span class="font-weight-bold">操作紀錄</span>
        </template>

        <customer-view-history />
      </b-tab>

      <!-- tab: 設定 -->
      <b-tab active>
        <template #title>
          <b-img
            height="18"
            width="18"
            class="mr-50 menu-icon"
            :src="require('@/assets/images/pages/customer/ui-settings.svg')"
          />
          <span class="font-weight-bold">設定</span>
        </template>

        <customer-view-setting
          @refresh-data="refreshCustomerInfoData"
        />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
// API
import { onUnmounted } from '@vue/composition-api'
// UI
import { BTabs, BTab, BImg } from 'bootstrap-vue'

import store from '@/store'
import CustomerViewInfo from './CustomerViewInfo.vue'
import CustomerViewOrder from './CustomerViewOrder/CustomerViewOrder.vue'
import CustomerViewHistory from './CustomerViewHistory/CustomerViewHistory.vue'
import CustomerViewGeneral from './CustomerViewGeneral/CustomerViewGeneral.vue'
import CustomerViewWorkphase from './CustomerViewWorkphase.vue'
import CustomerViewSetting from './CustomerViewSetting/CustomerViewSetting.vue'
import { useCustomerViewGeneral } from '../useCustomer'
import useStoreModule from '../useStoreModule'
import router from '@/router'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    BImg,

    CustomerViewInfo,
    CustomerViewOrder,
    CustomerViewHistory,
    CustomerViewGeneral,
    CustomerViewWorkphase,
    CustomerViewSetting,
  },
  data() {
    return {
      tabIndex: 0,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  setup() {
    // 註冊模組
    const CUSTOMER_ADMIN_STORE_MODULE_NAME = 'admin-customer'

    if (!store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)
    })

    const customerID = router.currentRoute.params.id

    const {
      generalData,
      getCustomerInfoData,
    } = useCustomerViewGeneral()

    const refreshCustomerInfoData = () => {
      store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', true)
      getCustomerInfoData({ customer_id: customerID }, () => {
        store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    refreshCustomerInfoData()

    return {
      customerID,
      generalData,
      getCustomerInfoData,
      refreshCustomerInfoData,
    }
  },
}
</script>
