<template>
  <div>
    <b-tabs
      pills
      lazy
    >
      <!-- tab: 點數紀錄 -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="StopCircleIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">點數紀錄</span>
        </template>

        <history-quota-list />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import HistoryQuotaList from './CustomerViewHistoryQuotaList.vue'

export default {
  components: {
    BTabs,
    BTab,
    HistoryQuotaList,
  },
  data() {
    return {
    }
  },
  methods: {
  },
  setup() {
    return {
    }
  },
}
</script>
