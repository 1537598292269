<template>
  <div>
    <b-skeleton-wrapper :loading="adminCustomerState.isBusyLoading">
      <template #loading>
        <b-card>
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-avatar
                  rounded
                  size="9rem"
                />
              </b-link>
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <div class="p-2">
                <b-skeleton
                  animation="wave"
                  width="85%"
                />
                <b-skeleton
                  animation="wave"
                  width="55%"
                />
                <b-skeleton
                  animation="wave"
                  width="70%"
                />
              </div>
            </b-media-body>
          </b-media>
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <div v-if="!adminCustomerState.isBusyLoading">
      <b-card>
        <b-row>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column mb-1"
          >
            <div class="d-flex justify-content-start">
              <div class="profile-image">
                <b-avatar
                  ref="previewEl"
                  size="9rem"
                  rounded
                  :src="adminCustomerState.customerInfo.image"
                  variant="light-secondary"
                />

                <div
                  class="profile-image-mask animate__animated animate__fadeIn"
                >
                  <div
                    class="profile-icon profile-image-edit"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/edit-w.svg"
                      width="30"
                      height="30"
                    />
                  </div>
                  <div
                    v-if="adminCustomerState.customerInfo.image"
                    class="profile-icon profile-image-reset"
                    @click="removeImage"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/refresh-w.svg"
                      width="16"
                      height="16"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column ml-1">
                <div class="mb-50">
                  <h2 class="mb-0">
                    {{ adminCustomerState.customerInfo.name }} {{ adminCustomerState.customerInfo.family_name }}
                  </h2>
                  <span class="show-text">{{ adminCustomerState.customerInfo.account }}</span>
                </div>

                <div class="text-nowrap">
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="信箱驗證"
                    rounded
                    fluid
                    class="user-state-icon"
                    :style="{ 'opacity': adminCustomerState.customerInfo.email_state === 2 ? 1 : 0.3 }"
                    :src="require('@/assets/images/pages/customer/email.svg')"
                  />
                  <!-- :class="!adminCustomerState.customerInfo.branch_info.mail_valid_switch ? 'user-state-icon-hidden' : null" -->

                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="電話驗證"
                    rounded
                    fluid
                    class="user-state-icon"
                    :style="{ 'opacity': adminCustomerState.customerInfo.phone_state === 2 ? 1 : 0.3 }"
                    width="28"
                    :src="require('@/assets/images/pages/customer/security.svg')"
                  />
                  <!-- :class="!adminCustomerState.customerInfo.branch_info.phone_valid_switch ? 'user-state-icon-hidden' : null" -->

                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="身分驗證"
                    rounded
                    fluid
                    class="user-state-icon"
                    :style="{ 'opacity': adminCustomerState.customerInfo.kyc_state === 2 ? 1 : 0.3 }"
                    :src="require('@/assets/images/pages/customer/id-card.svg')"
                  />
                  <!-- :class="!adminCustomerState.customerInfo.branch_info.kyc_switch ? 'user-state-icon-hidden' : null" -->

                </div>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            xl="6"
          >
            <b-row>
              <b-col
                cols="12"
                xl="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="分站"
                  class="m-0"
                >
                  <div class="mt-50">
                    <span v-if="adminCustomerState.customerInfo.branch_info.branch">
                      {{ adminCustomerState.customerInfo.branch_info.name ? adminCustomerState.customerInfo.branch_info.name : adminCustomerState.customerInfo.branch_info.branch }}

                      <b-img
                        id="branch_info_state"
                        :src="$store.state.app.themeImages.infoImg"
                        width="22"
                        alt="分站詳情"
                      />

                      <b-tooltip
                        target="branch_info_state"
                        boundary-padding="10"
                        boundary="window"
                        placement="right"
                      >
                        <table>
                          <tr>
                            <td class="text-right">
                              信箱驗證：
                            </td>
                            <td class="text-left">
                              <div class="table-title">
                                {{ adminCustomerState.customerInfo.branch_info.mail_valid_switch ? '啟用' : '關閉' }}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="text-right">
                              手機驗證：
                            </td>
                            <td class="text-left">
                              <div class="table-title">
                                {{ adminCustomerState.customerInfo.branch_info.phone_valid_switch ? '啟用' : '關閉' }}
                              </div>
                            </td>
                          </tr>

                          <!-- <tr>
                            <td class="text-right">
                              身分驗證：
                            </td>
                            <td class="text-left">
                              <div class="table-title">
                                {{ adminCustomerState.customerInfo.branch_info.kyc_switch ? '啟用' : '關閉' }}
                              </div>
                            </td>
                          </tr> -->
                        </table>
                      </b-tooltip>
                    </span>
                    <span
                      v-else
                      class="text-muted"
                    >
                      尚未設定
                    </span>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="狀態"
                  class="m-0"
                >
                  <div class="mt-50">
                    <b-badge
                      :variant="ui.stateList[1][adminCustomerState.customerInfo.state]"
                    >
                      {{ ui.stateList[0][adminCustomerState.customerInfo.state] }}
                    </b-badge>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="4"
                  label="上次登入"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ adminCustomerState.customerInfo.last_login_at ? moment(adminCustomerState.customerInfo.last_login_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                xl="12"
                lg="6"
              >
                <b-form-group
                  label-cols="4"
                  label="上次更新"
                  class="m-0"
                >
                  <div class="mt-50">
                    {{ adminCustomerState.customerInfo.updated_at ? moment(adminCustomerState.customerInfo.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <!-- 上傳圖片 -->
      <b-form-file
        ref="refInputEl"
        accept=".jpg, .png"
        :hidden="true"
        plain
        @change="loadImage($event)"
      />

      <!-- 圖片裁剪 -->
      <b-modal
        id="base-image-modal"
        centered
        :no-close-on-backdrop="close"
        :no-close-on-esc="close"
      >

        <template #modal-header>
          <h4>圖片裁剪</h4>
        </template>

        <div class="upload-example">
          <cropper
            ref="cropper"
            :src="image"
            :stencil-props="{
              aspectRatio: 1/1,
            }"
            :canvas="{
              minHeight: 0,
              minWidth: 0,
              maxHeight: 300,
              maxWidth: 300,
            }"
            :auto-zoom="false"
            :debounce="false"
            @change="onChange"
          />

          <!-- <preview
            :width="200"
            :height="200"
            :image="result.image"
            :coordinates="result.coordinates"
          /> -->
        </div>

        <template #modal-footer>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="uploadImage"
          >
            上傳
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
// API
import moment from 'moment'
// UI
import {
  BSkeletonWrapper, BSkeleton, BCard, BMedia, BMediaAside, BMediaBody, BAvatar, BImg,
  BLink, BRow, BCol, BFormGroup, VBTooltip, BBadge, BFormFile, BModal, BButton, BTooltip,
  // BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useCustomerViewGeneral, useCustomerSetting } from '../useCustomer'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
// import store from '@/store'

export default {
  components: {
    BCard,
    BLink,
    BAvatar,
    BMedia,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BFormGroup,
    BFormFile,
    BModal,
    // BCardText,
    BMediaAside,
    BMediaBody,
    BSkeleton,
    BSkeletonWrapper,
    BTooltip,
    Cropper,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
    }
  },
  computed: {
    adminCustomerState() {
      return this.$store.state['admin-customer']
    },
  },
  methods: {
    moment,
    // -----------------------------------圖片上傳------------------------------------
    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (type !== 'jpg' && type !== 'png' && type !== 'PNG' && type !== 'JPG') return
      this.$bvModal.show('base-image-modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('data', blob)
          this.setCustomerImageUpload({
            customer_id: this.adminCustomerState.customerInfo.id,
            data: formData,
          })
            .then(response => {
              const image = response.data.data
              const resolveCustomerInfo = {
                ...this.adminCustomerState.customerInfo,
              }
              resolveCustomerInfo.image = image
              this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveCustomerInfo)
              this.useAlertToast(response.data.success, response.data.message)
            })
            .catch(error => {
              this.useSwalAlertCenter(false, '上傳失敗!', error.response.data.message)
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('base-image-modal')
      this.result.coordinates = null
    },

    // (刪除)圖片
    removeImage() {
      this.setCustomerImageDelete({
        customer_id: this.adminCustomerState.customerInfo.id,
      })
        .then(response => {
          const resolveCustomerInfo = {
            ...this.adminCustomerState.customerInfo,
          }
          resolveCustomerInfo.image = null
          this.$store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveCustomerInfo)
          this.useAlertToast(response.data.success, response.data.message)
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
        })
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('base-image-modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },
  },
  setup() {
    const {
      ui,
    } = useCustomerSetting()

    const {
      setCustomerUpdate,
      setCustomerImageUpload,
      setCustomerImageDelete,
    } = useCustomerViewGeneral()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      useAlertToast,
      useHttpCodeAlert,

      ui,
      setCustomerUpdate,
      setCustomerImageUpload,
      setCustomerImageDelete,
    }
  },
}
</script>

<style lang="scss" scoped>
.socialicon {
  max-width: 28px;
  margin-right: 12px;
  margin-bottom: 5px;
}
.nosocial {
  opacity: 0.2;
}

.user-state-icon {
  max-width: 30px;
  margin-right: 10px;
}

.user-state-icon-hidden {
  opacity: 0 !important;
}

// 圖片上傳
.profile-image {
  position: relative;
  .profile-image-mask {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000080;
    user-select: none;
    .profile-icon {
      cursor: pointer;
    }
    .profile-image-reset {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      // border: 1px #6f6f6fbd solid;
      background-color: #69bdf2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .profile-image-mask {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
